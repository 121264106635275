import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Facebook, Link, Mail, Twitter, Share } from 'react-feather';
import useClipboard from 'react-use-clipboard';
import { AtelierPopover } from '@components/ui/Popover';
import { Button } from '@components/ui/Button';

const hasNavigatorShare = Boolean(
  typeof window !== 'undefined' && navigator.share
);

function CopyTextButton({
  text,
  onCopied,
  ...props
}: { text: string; onCopied: () => void } & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) {
  const [copied, setCopied] = useClipboard(text, { successDuration: 1000 });
  useEffect(() => {
    if (copied) {
      onCopied();
    }
  }, [copied]);
  return (
    <span className="relative inline-flex items-center">
      <button onClick={setCopied} {...props} />
      <motion.div
        className="absolute top-0 px-1 py-1 text-sm text-green-900 -translate-y-1/2 bg-green-100 -left-1/2"
        animate={{ opacity: copied ? 1 : 0 }}
      >
        Kopiert
      </motion.div>
    </span>
  );
}

type ShareMethod = 'copy_url' | 'email' | 'facebook' | 'twitter';

export enum ShareUrlButtonVariant {
  BUTTON = 'button',
  ICON = 'icon',
}

interface BaseProps {
  url: string;
  title: string;
  onShared: (method?: ShareMethod) => void;
  variant?: ShareUrlButtonVariant;
}
interface ShareUrlProps extends BaseProps {
  emailAttributes?: string;
}

export function ShareUrlButton({
  url,
  emailAttributes,
  title,
  onShared,
  variant,
}: ShareUrlProps) {
  async function handleClicked() {
    if (!hasNavigatorShare) {
      return;
    }
    try {
      await navigator.share({
        title,
        url: url,
      });
      onShared();
    } catch (err) {
      // noop
    }
  }
  return (
    <AtelierPopover
      interactive
      trigger="click"
      disabled={hasNavigatorShare}
      render={(attrs) => {
        return (
          <div {...attrs} className="space-y-2">
            <CopyTextButton
              text={url}
              onCopied={() => {
                onShared('copy_url');
              }}
            >
              <div className="flex">
                <Link strokeWidth={1} className="mr-1" /> Copy link
              </div>
            </CopyTextButton>
            <a
              href={`mailto:${emailAttributes}`}
              target="blank"
              rel="noopener noreferrer"
              className="flex"
              onClick={() => {
                onShared('email');
              }}
            >
              <Mail strokeWidth={1} className="mr-1" /> Share on email
            </a>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
              target="blank"
              rel="noopener noreferrer"
              className="flex"
              onClick={() => {
                onShared('facebook');
              }}
            >
              <Facebook strokeWidth={1} className="mr-1" /> Share on Facebook
            </a>
            <a
              href={`https://twitter.com/intent/tweet?url=${url}&text=${title}&hashtags=kunst,atelier`}
              target="blank"
              rel="noopener noreferrer"
              className="flex"
              onClick={() => {
                onShared('twitter');
              }}
            >
              <Twitter strokeWidth={1} className="mr-1" /> Share on Twitter
            </a>
          </div>
        );
      }}
    >
      {variant === ShareUrlButtonVariant.BUTTON ? (
        <Button
          onClick={() => handleClicked()}
          iconLeft={<Share strokeWidth={1} />}
        >
          Share
        </Button>
      ) : (
        <button
          aria-label="Share"
          title="Share"
          onClick={() => handleClicked()}
        >
          <Share strokeWidth={1} size={20} />
        </button>
      )}
    </AtelierPopover>
  );
}
