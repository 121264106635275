import { LOCALE } from './constants';
import { CURRENCY } from './enums';
import { getCurrencyPrices } from './calculations';

export function numberFormat(
  value: number,
  options?: Intl.NumberFormatOptions
) {
  try {
    const currency = options ? options.currency : CURRENCY.NOK;
    return Intl.NumberFormat(LOCALE, { currency, ...options }).format(value);
  } catch (err) {
    return value.toString();
  }
}

export function currencyFormat(
  value: number,
  options?: Omit<Intl.NumberFormatOptions, 'style'>
) {
  let maximumFractionDigits, minimumFractionDigits;
  if (value % 1 === 0) {
    minimumFractionDigits = 0;
  } else {
    maximumFractionDigits = 0;
    minimumFractionDigits = 0;
  }
  const currency = options ? options.currency : CURRENCY.NOK;
  return numberFormat(value, {
    ...options,
    style: 'currency',
    currencyDisplay: 'symbol',
    maximumFractionDigits,
    minimumFractionDigits,
    currency,
  });
}

export function getAltAmountsFormatted(
  currency: CURRENCY,
  basePrice: number,
  subtotal: number,
  taxAmount: number,
  total: number
): {
  altAmountBasePrice: string;
  altAmountSubTotal: string;
  altAmountTaxAmount: string;
  altAmountTotal: string;
} {
  const formatOptions = getFormatOptions(currency);
  const altCurrencyPrices = getCurrencyPrices(
    basePrice,
    subtotal,
    taxAmount,
    total,
    currency
  );
  return {
    altAmountBasePrice: currencyFormat(
      altCurrencyPrices.basePrice,
      formatOptions
    ),
    altAmountSubTotal: currencyFormat(
      altCurrencyPrices.subTotal,
      formatOptions
    ),
    altAmountTaxAmount: currencyFormat(
      altCurrencyPrices.taxAmount,
      formatOptions
    ),
    altAmountTotal: currencyFormat(altCurrencyPrices.total, formatOptions),
  };
}

export function getFormatOptions(currency: CURRENCY): {
  currency: CURRENCY;
  currencyDisplay: string;
} {
  switch (currency) {
    case CURRENCY.NOK: {
      return { currency: CURRENCY.EUR, currencyDisplay: 'code' };
    }
    case CURRENCY.EUR: {
      return { currency: CURRENCY.NOK, currencyDisplay: 'code' };
    }
  }
}
