import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react/headless';
import { useSpring, motion } from 'framer-motion';

export const AtelierPopover = ({
  render,
  interactive = true,
  trigger = 'click',
  ...props
}: TippyProps) => {
  const springConfig = { damping: 30, stiffness: 300 };
  const initialScale = 1;
  const initialY = -10;
  const opacity = useSpring(0, springConfig);
  const scale = useSpring(initialScale, springConfig);
  const y = useSpring(initialY, springConfig);

  return (
    <Tippy
      interactive={interactive}
      animation={true}
      onMount={() => {
        scale.set(1);
        opacity.set(1);
        y.set(0);
      }}
      onHide={({ unmount }) => {
        const cleanup = scale.onChange((value) => {
          if (value <= initialScale) {
            cleanup();
            unmount();
          }
        });
        scale.set(initialScale);
        opacity.set(0);
        y.set(initialY);
      }}
      render={(attrs, content, instance) => {
        return (
          <motion.div
            tabIndex={-1}
            className="max-w-md px-4 py-2 bg-white rounded-sm shadow-md"
            style={{ y, scale, opacity }}
            {...attrs}
          >
            {render && render(attrs, content, instance)}
          </motion.div>
        );
      }}
      trigger={trigger}
      {...props}
    />
  );
};
