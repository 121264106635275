import React, { useState } from 'react';
import firebase from 'firebase/app';
import { useDocument } from 'react-firebase-hooks/firestore';
import { Heart } from 'react-feather';
import { useAuthModal } from '@components/auth/AuthModal';
import {
  trackFavouriteArtwork,
  trackRemoveFavouriteArtwork,
} from '@lib/analytics';
import {
  instance,
  URL_CREATE_FAVORITE,
  URL_DELETE_FAVORITE,
} from '../../../src/constants/https';
import { useAuthUser } from '@lib/authentication';

export function ArtworkFavorite({
  artworkId,
  userLoading,
}: {
  artworkId: string;
  userLoading: boolean;
}) {
  const { openAuthModal } = useAuthModal();
  const { user } = useAuthUser();
  const userId = user?.uid;

  function isOnClient() {
    return typeof window !== 'undefined';
  }

  const artworkDocRef =
    (isOnClient() &&
      firebase.firestore().collection('artworks').doc(artworkId)) ||
    null;

  const [snapshot, loading] = useDocument(artworkDocRef);
  const snapData = snapshot?.data();
  const favorites: Map<string, number> | undefined = snapData?.favorites;

  const hasFavorited = checkFavorites(favorites, userId);

  const [favoriteIsSaving, setFavoriteIsSaving] = useState(false);

  const hasFavoritedIcon = (
    <Heart fill="currentColor" strokeWidth={1} size={20} />
  );
  const notFavoritedIcon = <Heart strokeWidth={1} size={20} />;
  const title = hasFavorited ? 'Remove favorite' : 'Add as favorite';

  return (
    <button
      type="button"
      onClick={async () => {
        if (!user) {
          openAuthModal('Sign in to save as favorite');
          return;
        }
        const token = await user.getIdToken();

        if (!token) {
          openAuthModal('Sign in to save as favorite');
          return;
        }
        setFavoriteIsSaving(true);
        try {
          const uid = user.uid;
          const isError = true;

          if (hasFavorited) {
            await instance({ token })
              .post(URL_DELETE_FAVORITE, {
                artworkId,
                uid,
              })
              .catch((error) => {
                console.error('Delete favorite error: ', error);
                openAuthModal(
                  'Sorry we could not remove from favorites right now. We are working on it!',
                  isError
                );
              });
            trackRemoveFavouriteArtwork({ artworkId });
          } else {
            await instance({ token })
              .post(URL_CREATE_FAVORITE, {
                artworkId,
                uid,
              })
              .catch((error) => {
                console.error('Save favorite error: ', error);
                openAuthModal(
                  'Sorry we could not save as favorite right now. We are working on it!',
                  isError
                );
              });
            trackFavouriteArtwork({ artworkId });
            if (window.Intercom) {
              window.Intercom('trackEvent', 'favorite_artwork');
            }
          }
        } finally {
          setFavoriteIsSaving(false);
        }
      }}
      disabled={Boolean(favoriteIsSaving || userLoading || loading)}
      title={title}
      aria-label={title}
    >
      {hasFavorited ? hasFavoritedIcon : notFavoritedIcon}
    </button>
  );
}

function checkFavorites(
  favorites?: Map<string, number> | undefined,
  userId?: string
): boolean {
  if (userId && favorites) {
    const users = Object.keys(favorites);
    return users.includes(userId);
  }
  return false;
}
