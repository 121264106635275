import React from 'react';
import { WEB_ROOT_URL } from '@lib/constants';
import { useAuthUser } from '@lib/authentication';
import { ArtworkDetailsQuery } from '@generated/codegen';
import { EditArtworkLink } from '@components/common/routing';
import { Anchor } from '@components/ui/Anchor';
import { ArtworkFavorite } from './ArtworkFavorite';
import { trackShareItem } from '@lib/analytics';
import { ShareUrlButton } from '../../common/ShareUrl';
import classNames from 'classnames';

export function ArtworkHeaderActions({
  artwork,
  className,
  ...props
}: {
  artwork: NonNullable<ArtworkDetailsQuery['artwork']>;
} & React.HTMLAttributes<HTMLDivElement>) {
  const { user, loading: userLoading } = useAuthUser();
  const isOwner = user?.uid === artwork.studio?.id;
  const permalinkOrId = artwork.studio?.permalinkOrId;
  const permalink =
    permalinkOrId && `${WEB_ROOT_URL}/studio/${permalinkOrId}/a/${artwork.id}`;
  return (
    <div
      className={classNames('flex items-center space-x-2', className)}
      {...props}
    >
      {permalink && (
        <ShareUrlButton
          url={permalink}
          title={artwork.title || 'I found this on Atelie.art'}
          emailAttributes={`?subject=${artwork.title}&body=I found this on Atelie.art: ${permalink}`}
          onShared={(method) => {
            trackShareItem({
              content_type: 'artwork',
              item_id: artwork.id,
              method,
            });
          }}
        />
      )}
      {!isOwner && artwork.id && (
        <ArtworkFavorite artworkId={artwork.id} userLoading={userLoading} />
      )}
      {isOwner && artwork.id && (
        <EditArtworkLink id={artwork.id}>
          <Anchor className="hover:underline">Edit</Anchor>
        </EditArtworkLink>
      )}
    </div>
  );
}
