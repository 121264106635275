export enum ProductType {
  'artwork' = 'artwork',
  'editions' = 'editions',
  'frame' = 'frame',
  'gift_card_coupon' = 'gift_card_coupon',
}

export enum OrderType {
  'subscription' = 'subscription',
  'payment' = 'payment',
  'gift_card' = 'gift_card',
  'artwork' = 'artwork',
}

export enum CURRENCY {
  NOK = 'NOK',
  EUR = 'EUR',
}

export enum EditionsLocation {
  Vika = 'vika',
  Editions = 'editions',
  Editions_14 = 'editions_14',
  Editions_15 = 'editions_15',
  Kalender = 'kalender',
  Leie = 'leie',
  Editions_04 = 'editions_04',
  EditionsAamodt = 'editions_aamodt',
  EditionsPigao = 'editions_pigao',
  EditionsBarrios = 'editions_barrios',
  EditionsHvidsten = 'editions_hvidsten',
  EditionsTerins = 'editions_terins',
  EditionsGellein = 'editions_gellein',
  EditionsMikkelsgard = 'editions_mikkelsgard',
  EditionsDahl = 'editions_dahl',
}
