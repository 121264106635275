import { z } from 'zod';
import { CURRENCY } from './enums';
import { ONE_NOK_TO_EUR_RATE } from './constants';
import { currencyFormat, getAltAmountsFormatted } from './number-format';
export const ARTWORK_FEE_PERCENT = 0.15;

const ORE_IN_KRONE = 100;

const currencyAmountSchema = z.number().int().nonnegative();

export function calculateApplicationFeeAmount({
  amount,
  onBehalfOf,
}: {
  amount: number;
  onBehalfOf: boolean;
}) {
  if (onBehalfOf) {
    return Math.round(amount * ARTWORK_FEE_PERCENT);
  }
  return Math.round(amount * ARTWORK_FEE_PERCENT);
}

const amountDiscountSchema = z.object({
  amount: currencyAmountSchema,
  applicationFeeAmount: z.number().int().nonnegative(),
  remainingAmount: z.number().int().nonnegative(),
  giftCardId: z.string(),
  transfers: z.object({
    studio: z.object({
      accountId: z.string(),
      amount: z.number().int().nonnegative(),
      transferId: z.string().nullable(),
    }),
  }),
});

const baseAmountSchema = z.object({
  /**
   * Total amount before discounts or additions
   */
  subTotal: z.number().int().nonnegative(),
  /**
   * Kunstavgiften (BKH) amount OR DE tax amount
   */
  taxAmount: z.number().int().nonnegative(),
  /**
   * Discounts and gift cards
   */
  discountAmount: z.number().int().optional().nullable(),
  /**
   * Total after discounts
   */
  totalAmount: z.number().int().nonnegative(),
});

export const amountStripePaymentIntentSchema = baseAmountSchema
  .extend({
    type: z.literal('stripe-payment-intent'),
    /**
     * Application fee is calculated from the artwork price *before* BKH is added
     */
    applicationFeeAmount: z.number().int().nonnegative(),
    discount: amountDiscountSchema.nullable(),
  })
  .refine((data) => data.applicationFeeAmount <= data.totalAmount, {
    message: "applicationFeeAmount can't be more than total amount",
    path: ['applicationFeeAmount'],
  });

export const amountGiftCardSchema = baseAmountSchema.extend({
  type: z.literal('gift-card'),
  discount: amountDiscountSchema,
});

const amountsSchema = z
  .union([amountStripePaymentIntentSchema, amountGiftCardSchema])
  .refine(
    (data) =>
      data.discount
        ? data.discount.applicationFeeAmount <= data.discount.amount
        : true,
    {
      message:
        "discount.applicationFeeAmount can't be more than discount.amount",
      path: ['discount', 'applicationFeeAmount'],
    }
  );

function calculateDiscountApplicationFeeAmount(amount: number) {
  const bkhDiscountAmount = amountIsBKHEligible(amount)
    ? Math.round(amount - amount / (BKH_FEE_PERCENT + 1))
    : 0;
  return calculateApplicationFeeAmount({
    amount: amount - bkhDiscountAmount,
    // always use 15% application fee for discount
    onBehalfOf: true,
  });
}

export function calculateArtworkStripeAmounts({
  currency,
  artworkBasePrice,
  giftCard,
  stripeAccountIds,
  onBehalfOf,
}: {
  currency: string;
  artworkBasePrice: number | string;
  giftCard: {
    id: string;
    canBeRedeemed: boolean;
    remainingAmount: number;
  } | null;
  stripeAccountIds: {
    studio: string;
  } | null;
  onBehalfOf: boolean;
}) {
  /** RULES:
   *
   * For both currencies
   * - Check whether application fees are being applied
   * - BKH or added tax goes to Atelie for correct distribution (added to application_fee)
   *
   * If currency is NOK:
   * - Check if kunstavgiften (BKH) should be applied
   * - Check if gift cards are being applied
   *
   * If currency is EUR:
   * - No BKH, no tax (currently)
   * - Gift cards cannot be applied
   */

  // const onBehalfOf = Boolean(promotion && stripeAccountIds.studio);
  // artworkBasePrice in firebase might be a string
  const artworkBasePriceNumber =
    typeof artworkBasePrice === 'string'
      ? parseFloat(artworkBasePrice)
      : artworkBasePrice;

  const currencyTyped = CURRENCY[currency as keyof typeof CURRENCY];

  switch (currencyTyped) {
    case CURRENCY.EUR:
      return getDeArtworkAmounts(artworkBasePriceNumber, onBehalfOf);
    case CURRENCY.NOK:
      return getNokArtworkAmounts({
        artworkPrice: artworkBasePriceNumber,
        giftCard,
        stripeAccountIds,
        onBehalfOf,
      });
  }
}

export const BKH_FEE_PERCENT = 0.05;
export const BKH_PRICE_LIMIT = 2001;
export const DE_SALES_TAX = 0.07;

function getDeArtworkAmounts(artworkPrice: number, onBehalfOf: boolean) {
  const artworkBaseAmount = artworkPrice * ORE_IN_KRONE;

  const { taxAmount, priceWithTax } =
    calculateArtworkPriceWithDeTax(artworkBaseAmount);

  // Since gift cards cannot be used currently we simply return full price
  const discount = null;
  const discountAmount = 0;

  const amountSubtotal = priceWithTax;
  const amountTotal = amountSubtotal - discountAmount;

  const applicationFeeAmount = calculateApplicationFeeAmount({
    amount: artworkBaseAmount - discountAmount,
    onBehalfOf,
  });
  const input: z.infer<typeof amountsSchema> = {
    type: 'stripe-payment-intent',
    subTotal: priceWithTax,
    taxAmount,
    discount,
    totalAmount: amountTotal,
    applicationFeeAmount,
  };
  return amountsSchema.parse(input);
}

function getNokArtworkAmounts({
  artworkPrice,
  giftCard,
  stripeAccountIds,
  onBehalfOf,
}: {
  artworkPrice: number;
  giftCard: {
    id: string;
    canBeRedeemed: boolean;
    remainingAmount: number;
  } | null;
  stripeAccountIds: {
    studio: string;
  } | null;
  onBehalfOf: boolean;
}) {
  const artworkBaseAmount = artworkPrice * ORE_IN_KRONE;
  const { bkhAmount, priceWithBkh } =
    calculateArtworkPriceWithBKH(artworkBaseAmount);

  const amountOff = (giftCard?.canBeRedeemed && giftCard.remainingAmount) || 0;
  const requiresPayment = amountOff - artworkBaseAmount - bkhAmount < 0;

  let discount = null;
  if (giftCard?.canBeRedeemed && amountOff > 0) {
    const discountAmount = Math.min(amountOff, artworkBaseAmount + bkhAmount);
    const remainingAmount = Math.max(
      amountOff - artworkBaseAmount - bkhAmount,
      0
    );
    const discountApplicationFeeAmount =
      calculateDiscountApplicationFeeAmount(discountAmount);
    if (!stripeAccountIds) {
      throw new Error('stripeAccountIds is required when using promotion');
    }
    discount = {
      amount: discountAmount,
      applicationFeeAmount: discountApplicationFeeAmount,
      remainingAmount,
      giftCardId: giftCard.id,
      transfers: {
        studio: {
          accountId: stripeAccountIds.studio,
          transferId: null,
          amount: discountAmount - discountApplicationFeeAmount,
        },
      },
    };
  }

  const discountAmount = discount ? discount.amount : 0;
  const amountSubtotal = priceWithBkh;
  const amountTotal = amountSubtotal - discountAmount;

  let input: z.infer<typeof amountsSchema>;
  if (discount && !requiresPayment) {
    input = {
      type: 'gift-card',
      subTotal: amountSubtotal,
      taxAmount: bkhAmount,
      discount,
      totalAmount: amountTotal,
    };
  } else {
    const applicationFeeAmount = calculateApplicationFeeAmount({
      amount: artworkBaseAmount - discountAmount,
      onBehalfOf,
    });
    input = {
      type: 'stripe-payment-intent',
      subTotal: amountSubtotal,
      taxAmount: bkhAmount,
      discount,
      totalAmount: amountTotal,
      applicationFeeAmount: applicationFeeAmount,
    };
  }
  return amountsSchema.parse(input);
}

/**
 * Kunstavgiften (BKH)
 */

/**
 * Check if Artwork must pay Kunstavgiften (BKH)
 */
function amountIsBKHEligible(amount: number): boolean {
  return amount >= BKH_PRICE_LIMIT * ORE_IN_KRONE;
}

/**
 * Calculate amount for Kunstavgiften (BKH)
 */
export function calculateBKHAmount(amount: number): number {
  if (amountIsBKHEligible(amount)) {
    return amount * BKH_FEE_PERCENT;
  }
  return 0;
}

export function calculateArtworkPriceWithBKH(basePrice: number): {
  bkhAmount: number;
  priceWithBkh: number;
} {
  const bkhPrice = calculateBKHAmount(basePrice);
  return {
    bkhAmount: bkhPrice,
    priceWithBkh: basePrice + bkhPrice,
  };
}

/**
 *  EUR sales tax
 */

/**
 * Calculate amount for German sales tax (7%)
 */
export function calculateArtworkPriceWithDeTax(amount: number): {
  taxAmount: number;
  priceWithTax: number;
} {
  const tax = 0;
  return {
    taxAmount: tax,
    priceWithTax: amount + tax,
  };
}

/**
 * Final display price
 */

export function calculateDisplayArtworkPrice(
  basePrice: number | string,
  currency?: string
): {
  taxAmount: number;
  finalAmount: number;
} {
  const basePriceNumber =
    typeof basePrice === 'string' ? parseFloat(basePrice) : basePrice;

  switch (currency) {
    case CURRENCY.EUR: {
      const { taxAmount, priceWithTax } =
        calculateArtworkPriceWithDeTax(basePriceNumber);
      return {
        taxAmount,
        finalAmount: priceWithTax,
      };
    }
    default:
    case CURRENCY.NOK: {
      const { bkhAmount, priceWithBkh } = calculateArtworkPriceWithBKH(
        basePriceNumber * ORE_IN_KRONE
      );
      return {
        taxAmount: bkhAmount / ORE_IN_KRONE,
        finalAmount: priceWithBkh / ORE_IN_KRONE,
      };
    }
  }
}

function convertPrices(
  subtotal: number,
  taxAmount: number,
  total: number
): { subtotal: number; taxAmount: number; total: number } {
  return {
    subtotal: subtotal / 100,
    taxAmount: taxAmount / 100,
    total: total / 100,
  };
}

export type ArtworkDisplayPrices = {
  native: {
    basePrice: string;
    subtotal: string;
    taxAmount: string;
    total: string;
  };
  alternate: {
    basePrice: string;
    subtotal: string;
    taxAmount: string;
    total: string;
  };
};

export function calculateDisplayCheckoutPrices(
  subtotal: number,
  taxAmount: number,
  total: number,
  currency: CURRENCY
): ArtworkDisplayPrices {
  const convertedPrices = convertPrices(subtotal, taxAmount, total);

  const basePrice = convertedPrices.subtotal - convertedPrices.taxAmount;
  const basePriceFormatted = currencyFormat(basePrice, { currency });
  const subtotalPriceFormatted = currencyFormat(convertedPrices.subtotal, {
    currency,
  });
  const taxAmountFormatted = currencyFormat(convertedPrices.taxAmount, {
    currency,
  });
  const totalPriceFormatted = currencyFormat(convertedPrices.total, {
    currency,
  });

  const altAmountsFormatted = getAltAmountsFormatted(
    currency,
    basePrice,
    convertedPrices.subtotal,
    convertedPrices.taxAmount,
    convertedPrices.total
  );

  switch (currency) {
    case CURRENCY.EUR: {
      return {
        native: {
          basePrice: basePriceFormatted,
          subtotal: subtotalPriceFormatted,
          taxAmount: taxAmountFormatted,
          total: totalPriceFormatted,
        },
        alternate: {
          basePrice: altAmountsFormatted.altAmountBasePrice,
          subtotal: altAmountsFormatted.altAmountSubTotal,
          taxAmount: altAmountsFormatted.altAmountTaxAmount,
          total: altAmountsFormatted.altAmountTotal,
        },
      };
    }
    case CURRENCY.NOK: {
      return {
        native: {
          basePrice: basePriceFormatted,
          subtotal: subtotalPriceFormatted,
          taxAmount: taxAmountFormatted,
          total: totalPriceFormatted,
        },
        alternate: {
          basePrice: altAmountsFormatted.altAmountBasePrice,
          subtotal: altAmountsFormatted.altAmountSubTotal,
          taxAmount: altAmountsFormatted.altAmountTaxAmount,
          total: altAmountsFormatted.altAmountTotal,
        },
      };
    }
  }
}

export function calculateDisplayArtworkPrices(
  subtotal: number,
  taxAmount: number,
  basePrice: number,
  currency: CURRENCY
): ArtworkDisplayPrices {
  basePrice = basePrice === 0 ? subtotal - taxAmount : basePrice;
  const basePriceFormatted = currencyFormat(basePrice, { currency });
  const subtotalPriceFormatted = currencyFormat(subtotal, {
    currency,
  });
  const taxAmountFormatted = currencyFormat(taxAmount, {
    currency,
  });

  const bkhPrice = calculateDisplayArtworkPrice(basePrice, currency);
  const totalPriceFormatted = currencyFormat(bkhPrice.finalAmount, {
    currency,
  });

  const altAmountsFormatted = getAltAmountsFormatted(
    currency,
    basePrice,
    subtotal,
    taxAmount,
    bkhPrice.finalAmount
  );

  switch (currency) {
    case CURRENCY.EUR: {
      return {
        native: {
          basePrice: basePriceFormatted,
          subtotal: subtotalPriceFormatted,
          taxAmount: taxAmountFormatted,
          total: totalPriceFormatted,
        },
        alternate: {
          basePrice: altAmountsFormatted.altAmountBasePrice,
          subtotal: altAmountsFormatted.altAmountSubTotal,
          taxAmount: altAmountsFormatted.altAmountTaxAmount,
          total: altAmountsFormatted.altAmountTotal,
        },
      };
    }
    case CURRENCY.NOK: {
      return {
        native: {
          basePrice: basePriceFormatted,
          subtotal: subtotalPriceFormatted,
          taxAmount: taxAmountFormatted,
          total: totalPriceFormatted,
        },
        alternate: {
          basePrice: altAmountsFormatted.altAmountBasePrice,
          subtotal: altAmountsFormatted.altAmountSubTotal,
          taxAmount: altAmountsFormatted.altAmountTaxAmount,
          total: altAmountsFormatted.altAmountTotal,
        },
      };
    }
  }
}

export function getCurrencyPrices(
  basePrice: number,
  subTotal: number,
  taxAmount: number,
  total: number,
  currency: CURRENCY
): {
  basePrice: number;
  subTotal: number;
  taxAmount: number;
  total: number;
} {
  switch (currency) {
    case CURRENCY.EUR: {
      return {
        basePrice: Math.ceil(basePrice / ONE_NOK_TO_EUR_RATE),
        subTotal: Math.ceil(subTotal / ONE_NOK_TO_EUR_RATE),
        taxAmount: Math.ceil(taxAmount / ONE_NOK_TO_EUR_RATE),
        total: Math.ceil(total / ONE_NOK_TO_EUR_RATE),
      };
    }
    case CURRENCY.NOK: {
      return {
        basePrice: Math.ceil(basePrice * ONE_NOK_TO_EUR_RATE),
        subTotal: Math.ceil(subTotal * ONE_NOK_TO_EUR_RATE),
        taxAmount: Math.ceil(taxAmount * ONE_NOK_TO_EUR_RATE),
        total: Math.ceil(total * ONE_NOK_TO_EUR_RATE),
      };
    }
  }
}
