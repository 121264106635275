import { calculateDisplayArtworkPrices } from '@shared/calculations';
import { CURRENCY } from '@shared/enums';

export function ArtworkPrice(
  basePrice: number,
  tax: number,
  currency: CURRENCY
) {
  const ArtworkPrices = calculateDisplayArtworkPrices(
    0,
    tax,
    basePrice,
    currency
  );
  return (
    <>
      <a className="truncate text-sm text-gray-600">
        {ArtworkPrices.native.total} / {ArtworkPrices.alternate.total}
      </a>
    </>
  );
}
